<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>数据统计</el-breadcrumb-item>
        <el-breadcrumb-item>进口冷链商品申报统计</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="">
      <div class="echarts" style="margin: auto 0">

        <el-row class=" c999 f14">
          <div class="col-4" :class="{'greenBG':val1 == 'zpc'}" @click="getList('zpc')">进口冷链总批次<p class="mt5"><span
                class="c333 f25 fb mr5">{{data1.zpc}}</span>家</p>
          </div>
          <div class="col-4" :class="{'greenBG':val1 == 'coldChain_transport_vehicle'}"
            @click="getList('coldChain_transport_vehicle')"> 冷链运输车次数<p class="mt5"><span
                class="c333 f25 fb mr5">{{data1.coldChain_transport_vehicle}}</span>
            </p>
          </div>
        </el-row>
      </div>
      <el-row class="box search mt20" :gutter="10">
        <el-form :inline="true" :model="page" class="w" status-icon style="text-align: left">
          <el-col :span="4" v-if="val1!=='coldChain_transport_vehicle'">
            <el-form-item size="small">
              <el-input placeholder="请输入检验检疫证编号" clearable v-model="page.quarantine_cert_number">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="small" v-if="val1!=='coldChain_transport_vehicle'">
              <el-input placeholder="请输入商品名称" v-model="page.product_name" clearable class="input-with-select"
                @clear="onSearch">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="small" v-if="val1!=='coldChain_transport_vehicle'">
              <el-input placeholder="请输入公司名称" v-model="page.company_name" clearable class="input-with-select"
                @clear="onSearch">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2" class="tl mr10" v-if="val1!=='coldChain_transport_vehicle'">
            <el-form-item size="small">
              <el-button type="primary" @click="onSearch" class="general_bgc general_border cfff">查询</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div class="box list  mt20" v-if="val1!=='coldChain_transport_vehicle'">
        <div class="title f15">
          <p><span class="triangle-right fl ml10 mr10"></span>统计明细表</p>
          <!-- <el-button type="primary" v-if="loading==false" size="small" @click="DownExcel">导出数据</el-button>
          <el-button v-else-if="loading==true" type="info" disabled size="small">导出数据</el-button> -->
        </div>
        <div class="pannerArea mt15">
          <ul>
            <el-row class="w area-title tl pl10  flex" style="text-align: center">
              <el-col :span="4" class="omg" style="text-align: left">入境检验检疫证编号</el-col>
              <el-col :span="5">商品名称</el-col>
              <el-col :span="3" >入境检验检疫证图片</el-col>
              <el-col :span="3" >核酸检测报告</el-col>
              <el-col :span="3" >消毒证明</el-col>
              <el-col :span="3" >出仓证明</el-col>
              <el-col :span="5">公司名称</el-col>
              <el-col :span="4">统一社会编码</el-col>
              <el-col :span="3">公司营业执照</el-col>
              <el-col :span="4">申报时间</el-col>
              <!-- <span class="iconAdd el-icon-arrow-right c-trans "></span> -->
            </el-row>
            <div v-if="tableData.length>0">
              <li v-for="(item,index) in tableData" :key="index" class="">
                <div>
                  <el-row class="w  tl flex" style="text-align: center">
                    <el-col :span="4" class="omg" style="text-align: left">
                      {{item.quarantine_cert_number}}
                    </el-col>
                    <el-col :span="5">{{item.product_name}}</el-col>
                    <el-col :span="3" style="text-align: center" v-if="item.quarantine_cert_url!==''">
                      <el-button type="primary" size="small" @click="openImage(item.quarantineId,'.quarantine_cert')"
                        class="general_bgc general_border cfff">查看</el-button>
                      <template>
                        <div v-show="false" v-viewer="{movable: true,rebuild:true}" class="quarantine_cert">
                          <img v-for="src in item.quarantineCertUrl" :src="src.url" :key="src.id" width="50px"
                            height="10px">
                        </div>
                      </template>
                    </el-col>
                    <el-col :span="3" style="text-align: center" v-else>
                      【无检验检疫证】
                    </el-col>
                    <el-col :span="3" style="text-align: center" v-if="item.ncovid_reports_urls!==''">
                      <el-button type="primary" size="small" @click="openImage(item.nucleicAcidId,'.ncovid_reports')"
                        class="general_bgc general_border cfff">查看</el-button>
                      <template>
                        <div v-show="false" v-viewer="{movable: true,rebuild:true}" class="ncovid_reports">
                          <img v-for="src in item.ncovidReportsUrl" :src="src.url" :key="src.id" width="50px"
                            height="10px">
                        </div>
                      </template>
                    </el-col>
                    <el-col :span="3" style="text-align: center" v-else>
                      【无核酸检测报告】
                    </el-col>
                    <el-col :span="3" style="text-align: center" v-if="item.disinfection_cert_urls!==''">
                      <el-button type="primary" size="small"
                        @click="openImage(item.disinfectionId,'.disinfection_cert')"
                        class="general_bgc general_border cfff">查看</el-button>
                      <template>
                        <div v-show="false" v-viewer="{movable: true,rebuild:true}" class="disinfection_cert">
                          <img v-for="src in item.disinfectionCertUrl" :src="src.url" :key="src.id" width="50px"
                            height="10px">
                        </div>
                      </template>
                    </el-col>
                    <el-col :span="3" style="text-align: center" v-else>
                      【无消毒证明】
                    </el-col>
                    <el-col :span="3" style="text-align: center" v-if="item.warehouse_cert_urls!==''">
                      <el-button type="primary" size="small" @click="openImage(item.factoryId,'.warehouse_cert')"
                        class="general_bgc general_border cfff">查看</el-button>
                      <template>
                        <div v-show="false" v-viewer="{movable: true,rebuild:true}" class="warehouse_cert">
                          <img v-for="src in item.warehouseCertUrl" :src="src.url" :key="src.id" width="50px"
                            height="10px">
                        </div>
                      </template>
                    </el-col>
                    <el-col :span="3" style="text-align: center" v-else>
                      【无出仓证明】
                    </el-col>
                    <el-col :span="5" style="text-align: center;margin-top: 0px;" >
                      {{item.company_name}}
                    </el-col>
                    <el-col :span="4">{{item.credit_code}}</el-col>
                    <el-col :span="3" style="text-align: center " class="label" v-if="item.accessory_url!==''">
                      <el-button type="primary" size="small" @click="businessLicense(index,item)"
                        class="general_bgc general_border cfff">查看</el-button>
                    </el-col>
                    <el-col :span="3" style="text-align: center" v-else>
                      没有上传营业执照
                    </el-col>
                    <el-col :span="4">{{item.time}}</el-col>

                  </el-row>

                </div>
              </li>
            </div>
            <el-row v-else class="w tc pl10 mb20 flex text-no">
              <el-col :span="24" class="p20">
                暂无数据
              </el-col>
            </el-row>
          </ul>
        </div>
        <div class="i-page fr disib mt20">
          <el-pagination background :page-size='10' layout="total,prev, pager, next" :total="total"
            :current-page="currentPage" @current-change='currentChange'>
          </el-pagination>
        </div>
      </div>

      <div class="box list  mt20" v-else>
        <div class="title f15">
          <p><span class="triangle-right fl ml10 mr10"></span>统计明细表</p>
          <!-- <el-button type="primary" v-if="loading==false" size="small" @click="DownExcel">导出数据</el-button>
          <el-button v-else-if="loading==true" type="info" disabled size="small">导出数据</el-button> -->
        </div>
        <div class="pannerArea mt15">
          <ul>
            <el-row class="w area-title tl pl10 pr10 flex" style="text-align: center">
              <el-col :span="2" class="omg" style="text-align: left">车牌号</el-col>
              <el-col :span="3">商品名</el-col>
              <el-col :span="3">发货公司</el-col>
              <el-col :span="4">发货公司统一社会信用代码</el-col>
              <el-col :span="3">发货公司营业执照</el-col>
              <el-col :span="3">收货公司</el-col>
              <el-col :span="4">收货公司统一社会信用代码</el-col>
              <el-col :span="3">收货公司营业执照</el-col>
              <!-- <span class="iconAdd el-icon-arrow-right c-trans "></span> -->
            </el-row>
            <div v-if="tableData.length>0">
              <li v-for="(item,index) in tableData" :key="index" class="">
                <div>
                  <el-row class="w area-title tl pl10 pr10 flex" style="text-align: center">
                    <el-col :span="2" class="omg" style="text-align: left">
                      {{item.car_number}}
                    </el-col>
                    <el-col :span="3">{{item.goods_name}}</el-col>
                    <el-col :span="3" style="text-align: center">
                      {{item.fh_company_name}}
                    </el-col>
                    <el-col :span="4" class=" omg">{{item.fh_company_code}}</el-col>
                    <el-col :span="3" style="text-align: center " class="label">
                      <el-button type="primary" size="small" @click="fhBusinessLicense(index,item)"
                        class="general_bgc general_border cfff">查看</el-button>
                    </el-col>
                    <el-col :span="3" style="text-align: center">
                      {{item.sh_company_name}}
                    </el-col>
                    <el-col :span="4">{{item.sh_company_code}}</el-col>
                    <el-col :span="3">
                      <el-button type="primary" size="small" @click="shBusinessLicense(index,item)"
                        class="general_bgc general_border cfff">查看</el-button>
                    </el-col>
                  </el-row>

                </div>
              </li>
            </div>
            <el-row v-else class="w tc pl10 mb20 flex text-no">
              <el-col :span="24" class="p20">
                暂无数据
              </el-col>
            </el-row>
          </ul>
        </div>
        <div class="i-page fr disib mt20">
          <el-pagination background :page-size='10' layout="total,prev, pager, next" :total="total"
            :current-page="currentPage" @current-change='currentChange'>
          </el-pagination>
        </div>
      </div>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
      <el-image-viewer style="z-index: 9999" v-if="showViewer" :on-close="closeViewer" :url-list="[accessory_url]" />
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { local } from "../../../util/util";

import ElImageViewer from "element-ui/packages/image/src/image-viewer";

let option;
export default {
  components: {
    ElImageViewer,
  },
  filters: {
    statusFilter (status) {
      const statusMap = {
        2: '四川省小经营店登记证（流通）',
        5: '食品经营许可证（流通）',
        13: '农产品生产企业',
        1: '四川省小作坊登记证',
        18: '食品经营许可证（生产）',
        15: '市场开办方',
        16: '贮存服务提供者',
        3: '食品经营许可（餐饮）',
        4: '四川省小经营店登记证（餐饮）',
        19: '运输公司',
        17: '第三方检测单位',
      }
      return statusMap[status]
    },

  },
  data () {
    return {
      imageUrl: [],
      page: {
        quarantine_cert_number: '',
        company_name: '',
        product_name: '',
        pager_offset: "0",
      },
      tableData: [],
      accessory_url: '',
      bizList: [],
      userData: {},
      data1: '',
      timer: '',
      currentPage: 1,
      total: 0,
      currentIdx: '',
      val1: 'zpc',
      loading: false,
      expandRow: '',
      dialogVisible: false,
      dialogImageUrl: '',
      list1: '',
      showBtn: false,
      showViewer: false,
      pickerOptionsStart: {
        disabledDate: time => {
          if (this.page.search_time_end) {
            return time.getTime() > new Date(this.page.search_time_end).getTime()
          } else {
            return time.getTime() > Date.now()
          }
        }
      },
      pickerOptionsEnd: {
        disabledDate: time => {
          return time.getTime() > Date.now()
        }
      },
    };
  },
  computed: {},
  mounted () {
    this.userData = local.get("user");
    this.init(this.page);
    this.getList('zpc')
  },
  methods: {
    openImage (id, className) {
      //获取当前页面的所有标签的信息
      const image = this.$el.querySelectorAll(className);
      image[id].$viewer.show();//根据点击的index展示图片
    },
    businessLicenseImage (companyId) {
      axios.get('/pc/importedColdChainGoods/businessLicense/' + companyId).then(res => {
        res = res.data;
        this.accessory_url = res.accessory_url;
        this.showViewer = true;
      })
    },
    businessLicense (index, item) {
      this.businessLicenseImage(item.company_id);
    },
    //发货营业执照
    fhBusinessLicense (index, item) {
      this.businessLicenseImage(item.fh_company_id);
    },
    //收货营业执照
    shBusinessLicense (index, item) {
      this.businessLicenseImage(item.sh_company_id);
    },
    handleSelectionChange (data) {
      // this.prepareDeleteData = data
    },
    closeViewer () {
      this.showViewer = false;
    },
    init (params) {
      axios.get('/pc/importedColdChainGoods/data-statistics', params).then((v) => {
        this.data1 = v.data
      })
    },
    getList (val) {
      var that = this;
      that.currentPage = 1
      that.page.company_name = ''
      that.val1 = val
      that.page.pager_offset = '0'
      that.getData(val)
    },
    getData (params) {
      var that = this;
      axios.get('/pc/importedColdChainGoods/' + params + '/list', this.page).then((v) => {
        that.tableData = v.data.zpc_list;
        that.total = v.data.zpc_count;
        this.tableData.nucleicAcidId = 0;
        this.tableData.disinfectionId = 0;
        this.tableData.factoryId = 0;
        this.tableData.quarantineId = 0;
        for (let i = 0; i < this.tableData.length; i++) {
          if (this.tableData[i].ncovid_reports_urls !== '') {
            //核酸检测图片
            this.tableData[i].ncovidReportsUrl = JSON.parse(this.tableData[i].ncovid_reports_urls);
            this.tableData[i].nucleicAcidId = this.tableData.nucleicAcidId;
            this.tableData.nucleicAcidId++;
          }//根据查询的参数判断要解析哪一个页面的图片
          if (this.tableData[i].disinfection_cert_urls !== '') {
            //消毒证明
            this.tableData[i].disinfectionCertUrl = JSON.parse(this.tableData[i].disinfection_cert_urls);
            this.tableData[i].disinfectionId = this.tableData.disinfectionId;
            this.tableData.disinfectionId++;
          }
          if (this.tableData[i].warehouse_cert_urls !== '') {
            //出厂证明
            this.tableData[i].warehouseCertUrl = JSON.parse(this.tableData[i].warehouse_cert_urls);
            this.tableData[i].factoryId = this.tableData.factoryId
            this.tableData.factoryId++;
          }
          if (this.tableData[i].quarantine_cert_url !== '') {
            //检验检疫证
            this.tableData[i].quarantineCertUrl = JSON.parse(this.tableData[i].quarantine_cert_url);
            this.tableData[i].quarantineId = this.tableData.quarantineId
            this.tableData.quarantineId++;
          }
        }
      }).catch(() => {
      })
      if (that.val1 === 'company-circulate') {
        this.getbizList()
      }
    },
    // 分页
    currentChange (page) {
      var that = this;
      that.currentPage = page;
      that.page.pager_offset = String((page - 1) * 10)
      that.getData(that.val1)
    },
    // 搜索
    onSearch () {
      this.getData(this.val1)
    },
  },

};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;

  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .echarts {
    .greenBG {
      background-color: #f5f5f5;
    }

    .col-4 {
      float: left;
      width: 50%;
      border: 1px solid #ccc;
      border-right: none;
      padding: 10px;
    }

    .col-4:last-child {
      border-right: 1px solid #ccc;
    }
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .triangle-right {
      display: inline-block;
      vertical-align: bottom;
      width: 0;
      height: 0;
      border-width: 10px 0px 10px 16px;
      border-style: solid;
      border-color: transparent transparent transparent #ff992c;
    }
  }

  .pannerArea {
    ul {
      .area-title {
        .el-col {
          height: 60px;
          border-right: 1px solid #e6ecf6;
          line-height: 60px;
          padding-left: 10px;
        }

        .el-col:last-child {
          border-right: none;
        }

        background-color: #f5f5f5;
        border: 1px solid #e6ecf6;
        display: flex;
        align-items: center;
      }

      li {
        list-style: none;

        .el-row {
          .el-col {
            height: 60px;
            border-right: 1px solid #e6ecf6;
            line-height: 60px;
            padding-left: 10px;
          }

          .el-col:last-child {
            border-right: none;
          }

          background: #fff;
          border: 1px solid #e6ecf6;
          cursor: pointer;
          font-size: 14px;
          border-top: none;
          display: flex;
          align-items: center;
        }

        .el-form {
          padding-top: 10px;
          display: inline-block;
          width: 100%;
          border: 1px solid #f5f5f5;
        }
      }
    }
  }

  .text-no {
    border: 1px solid #f5f5f5;
    border-top: none;
  }

  /deep/ .el-table--border {
    border-top: none;
  }

  /deep/ .el-table .el-form-item {
    margin-bottom: 0px;
  }

  /deep/ .el-checkbox__label {
    display: none;
  }
}
</style>
